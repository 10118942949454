export const SHIPPING_STEP = 'SHIPPING_STEP';
export const BILLING_STEP = 'BILLING_STEP';
export const DETAILS_STEP = 'DETAILS_STEP';

export const CHECKOUT_URL = '/checkout';
export const SUCCESS_URL = '/success';
export const BILLING_URL = '/billing';

export const PAYMENT_TOTALS = 'PAYMENT_TOTALS';

export const UPDATE_EMAIL_CHECK_FREQUENCY = 1500; // ms

export const PROMO_SELECTOR = 'div[id$="-optin"]';
export const PROMO_SELECTOR_SECONDARY = 'div[id^="om-"][id$="-holder"]';

export * from '@scandipwa/scandipwa/src/route/Checkout/Checkout.config';
export const INSTANT_PAYPAL_BILLING_STEP = 'INSTANT_PAYPAL_BILLING_STEP';
