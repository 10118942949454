/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BlogReducer from 'Store/Blog/Blog.reducer';
import { updateCurrentCategory } from 'Store/Category/Category.action';
import CategoryReducer from 'Store/Category/Category.reducer';
import ProductReducer from 'Store/Product/Product.reducer';
import { setIsUrlRewritesLoading, setPageType } from 'Store/UrlRewrites/UrlRewrites.action';
import {
    HistoryType,
    LocationType,
    MatchType,
    UrlRewriteType
} from 'Type/Router.type';
import componentLoader from 'Util/componentLoader';
import { withReducers } from 'Util/DynamicReducer';

import UrlRewrites from './UrlRewrites.component';
import {
    TYPE_BLOG_CATEGORY,
    TYPE_BLOG_POST,
    TYPE_BLOG_TAG,
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from './UrlRewrites.config';

export const UrlRewritesDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/UrlRewrites/UrlRewrites.dispatcher'
), 2);

export const NoMatchDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/NoMatch/NoMatch.dispatcher'
), 2);
/** @namespace Bodypwa/Route/UrlRewrites/Container/mapStateToProps */
export const mapStateToProps = (state) => ({

    prevCategory: state.CategoryReducer.prevCategory,
    urlRewrite: state.UrlRewritesReducer.urlRewrite,
    isLoading: state.UrlRewritesReducer.isLoading,
    requestedUrl: state.UrlRewritesReducer.requestedUrl
});

/** @namespace Bodypwa/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    requestUrlRewrite: (urlParam) => {
        UrlRewritesDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, { urlParam })
        );
    },
    setIsUrlRewritesLoading: (isLoading) => dispatch(setIsUrlRewritesLoading(isLoading)),
    setPageType: (type) => dispatch(setPageType(type)),
    updateCurrentCategory: (category) => dispatch(updateCurrentCategory(category))
});

/** @namespace Bodypwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends PureComponent {
    static propTypes = {
        location: LocationType.isRequired,
        match: MatchType.isRequired,
        history: HistoryType.isRequired,
        isLoading: PropTypes.bool.isRequired,
        requestedUrl: PropTypes.string,
        requestUrlRewrite: PropTypes.func.isRequired,
        urlRewrite: UrlRewriteType.isRequired,
        setPageType: PropTypes.func.isRequired,
        prevCategory: PropTypes.shape({
            id: PropTypes.number
        }),
        updateCurrentCategory: PropTypes.func.isRequired
    };

    static defaultProps = {
        requestedUrl: '',
        prevCategory: {}
    };

    static stateMapping = {
        category: TYPE_CATEGORY,
        product: TYPE_PRODUCT,
        page: TYPE_CMS_PAGE
    };

    componentDidMount() {
        this.initialUrl = location.pathname;
    }

    componentDidUpdate() {
        /**
         * If the latest requested URL rewrite is not related
         * to the current location, and the URL rewrites are not loading
         * request new URL rewrite.
         */
        this.requestUrlRewrite();

        /**
         * Make sure that PDP & PLP url don't have "/" in the end
         */
        this.redirectToCorrectUrl();
    }

    __construct(props) {
        super.__construct(props);

        this.requestUrlRewrite();
    }

    containerProps = () => ({
        type: this.getType(),
        props: this.getProps()
    });

    redirectToCorrectUrl() {
        const { setPageType } = this.props;

        const type = this.getType();

        setPageType(type);
    }

    getTypeSpecificProps() {
        const {
            urlRewrite,
            prevCategory,
            prevCategory: { id: categoryId } = {},
            updateCurrentCategory
        } = this.props;

        const {
            id,
            sku
        } = urlRewrite;

        const default_sort_by = urlRewrite.category?.default_sort_by ?? 'position';

        const isLoading = this.getIsLoading();

        switch (this.getType()) {
        case TYPE_BLOG_POST:
            if (isLoading) {
                return { isOnlyPlaceholder: true };
            }

            return { id };
        case TYPE_BLOG_CATEGORY:
        case TYPE_BLOG_TAG:
            if (isLoading) {
                return { isOnlyPlaceholder: true };
            }

            return { id };
        case TYPE_PRODUCT:
            /**
             * In case we are not yet sure what product ID it is:
             * - check if there is a hint in browser history
             * - fallback to none
             */
            if (isLoading) {
                const product = history?.state?.state?.product;

                if (product) {
                    const { sku: historySKU, id } = product;

                    return { productSKU: historySKU, id };
                }

                return {};
            }

            return { productSKU: sku, id };
        case TYPE_CMS_PAGE:
            if (isLoading) {
                return { isOnlyPlaceholder: true };
            }

            return { pageIds: id };
        case TYPE_CATEGORY:
            /**
             * In case we are not yet sure what category ID it is:
             * - check if there is a hint in browser history
             * - fallback to none
             */
            if (isLoading) {
                const category = history?.state?.state?.category;
                const categoryDefaultSortBy = history?.state?.state?.categoryDefaultSortBy;

                if (category === categoryId) {
                    updateCurrentCategory(prevCategory);
                }
                if (category && category !== true) {
                    return { categoryIds: category, categoryDefaultSortBy };
                }

                return {};
            }

            return { categoryIds: id, categoryDefaultSortBy: default_sort_by };
        case TYPE_NOTFOUND:
        default:
            return {};
        }
    }

    getIsLoading() {
        const { requestedUrl } = this.props;

        return location.pathname !== requestedUrl;
    }

    requestUrlRewrite() {
        const { requestUrlRewrite, isLoading } = this.props;
        if (this.getIsLoading() && !isLoading) {
            return requestUrlRewrite(location.pathname);
        }

        return null;
    }

    getProps() {
        const {
            location,
            match,
            history
        } = this.props;

        return {
            location,
            match,
            history,
            ...this.getTypeSpecificProps()
        };
    }

    getFallbackType() {
        const {
            actionName: { type: initialType = '' } = {}
        } = window;

        if (this.initialUrl === location.pathname) {
            return initialType;
        }

        return '';
    }

    getType() {
        const { urlRewrite: { type, notFound } } = this.props;

        /**
         * If the URL rewrite is loading, prefer state-defined URL type,
         * else fallback to one defined in HTML document by PHP controller
         * (which is only valid for 1st load).
         */
        if (this.getIsLoading()) {
            const state = history?.state?.state || {};
            const typeKey = Object.keys(state).find((key) => UrlRewritesContainer.stateMapping[key]);

            if (typeKey) {
                return UrlRewritesContainer.stateMapping[typeKey];
            }

            /**
             * Otherwise fallback to other guessed types - from window i.e.
             */
            return this.getFallbackType();
        }

        if (notFound) {
            return TYPE_NOTFOUND;
        }

        if (type) {
            return type;
        }

        return '';
    }

    render() {
        return (
            <UrlRewrites
              { ...this.containerProps() }
            />
        );
    }
}

export default withReducers({
    BlogReducer,
    CategoryReducer,
    ProductReducer
})(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(UrlRewritesContainer)
);
