/** @namespace Bodypwa/Helper/Functions/escapeHtml */
export const escapeHtml = (unsafe) => unsafe
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;');

/** @namespace Bodypwa/Helper/Functions/isNotEmptyArr */
export const isNotEmptyArr = (arr) => Array.isArray(arr) && arr.length;
/** @namespace Bodypwa/Helper/Functions/isEmptyArr */
export const isEmptyArr = (arr) => !isNotEmptyArr(arr);

/** @namespace Bodypwa/Helper/Functions/makeId */
export const makeId = (length) => {
    // eslint-disable-next-line fp/no-let
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    // eslint-disable-next-line fp/no-let
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random()
      * charactersLength));
    }

    return result;
};
