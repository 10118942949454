export const TYPE_BLOG_CATEGORY = 'BLOG_CATEGORY';
export const TYPE_BLOG_TAG = 'BLOG_TAG';
export const TYPE_BLOG_POST = 'BLOG_POST';
export const TYPE_BLOG_HOMEPAGE = 'BLOG_HOMEPAGE';

export const BLOG_TYPE = {
    [TYPE_BLOG_CATEGORY]: 'CATEGORY',
    [TYPE_BLOG_TAG]: 'TAG',
    [TYPE_BLOG_POST]: 'POST',
    [TYPE_BLOG_HOMEPAGE]: 'HOMEPAGE'
};
export const TYPE_PRODUCT = 'PRODUCT';
export const TYPE_CMS_PAGE = 'CMS_PAGE';
export const TYPE_CATEGORY = 'CATEGORY';
export const TYPE_NOTFOUND = 'NOT_FOUND';
