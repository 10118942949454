import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { updateMeta } from 'Store/Meta/Meta.action';
import { ErrorDetailsType } from 'Type/Error.type';
import { getSentryInstance } from 'Util/SentryLoader/SentryLoader';

import SomethingWentWrong from './SomethingWentWrong.component';
import { PAGE_HAS_BEEN_FORCE_REFRESHED } from './SomethingWentWrong.config';
/** @namespace Bodypwa/Route/SomethingWentWrong/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateMeta: (meta) => dispatch(updateMeta(meta))
});

/** @namespace Bodypwa/Route/SomethingWentWrong/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device
});
/** @namespace Bodypwa/Route/SomethingWentWrong/Container */
export class SomethingWentWrongContainer extends PureComponent {
    static propTypes = {
        updateMeta: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        errorDetails: ErrorDetailsType.isRequired
    };

    state = {
        renderPage: false
    };

    componentDidMount() {
        const { updateMeta } = this.props;
        updateMeta({ title: __('Something went wrong!') });
        this.retryPageLoading();
    }

    retryPageLoading = () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.localStorage.getItem(PAGE_HAS_BEEN_FORCE_REFRESHED) || 'false'
        );

        if (!pageHasAlreadyBeenForceRefreshed && process.env.NODE_ENV === 'production') {
            window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, 'true');
            this.logError({ refresh: true, logToLocalStorage: true });
            window.location.reload();
        } else {
            window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, 'false');
            this.setState({ renderPage: true });
        }
    };

    logError() {
        const { errorDetails } = this.props;
        const Sentry = getSentryInstance();

        if (Sentry) {
            Sentry.captureEvent({ message: `Oops page!: ${ errorDetails?.info }` });
            Sentry.captureException(errorDetails?.err);
        } else {
            console.warn('Sentry not loaded, cannot log error to Sentry.');
        }
    }

    containerProps() {
        const { onClick, errorDetails } = this.props;

        return { onClick, errorDetails };
    }

    render() {
        const { renderPage } = this.state;
        if (!renderPage) {
            return null;
        }

        return (
            <SomethingWentWrong
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SomethingWentWrongContainer);
