Mosaic.setPlugins([]);
// eslint-disable-next-line simple-import-sort/sort
import 'react-app-polyfill/ie11';
import 'Util/Polyfill';
import 'Style/main';
import { render } from 'react-dom';
import App from 'Component/App';
import initSentry from 'Util/SentryLoader/SentryLoader';

initSentry();
// let's register service-worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        const swUrl = '/service-worker.js';
        navigator.serviceWorker
            .register(swUrl, { scope: '/' });
    });
}

render(<App />, document.getElementById('root'));
