import { PureComponent } from 'react';

import {
    addBlockReferenceAfter,
    addBlockReferenceAfterWrapper,
    addBlockReferenceBefore,
    addBlockReferenceBeforeWrapper
} from 'Component/LayoutUpdate';
import {
    BR_BEFORE_MAIN_COLUMNS,
    BR_BEFORE_PAGE_FOOTER,
    BR_BEFORE_PAGE_FOOTER_CONTAINER,
    BR_CHECKOUT_SUCCES,
    BR_CMS_FOOTER_LINKS,
    BR_MINI_CART_PROMOTION_BLOCK,
    BR_PAGE_BOTTOM, BR_PAGE_FOOTER,
    BR_PAGE_FOOTER_CONTAINER,
    BR_PAGE_HEADER,
    BR_PAGE_HEADER_CONTAINER,
    BR_PAGE_HEADER_PANEL,
    BR_PAGE_TOP,
    BR_PRODUCT_CONTAINER_LOWER_PRIMARY_2,
    BR_PRODUCT_IMAGE,
    BR_REVIEW_FORM_FIELDS_BEFORE
} from 'Util/blocks';

export const routeLayoutUpdates = [
    addBlockReferenceBefore(BR_PAGE_TOP),
    addBlockReferenceAfter(BR_BEFORE_MAIN_COLUMNS),
    addBlockReferenceAfter(BR_PAGE_BOTTOM)
];

export const topMenuLayoutUpdates = [
    addBlockReferenceBefore(BR_PAGE_HEADER_CONTAINER),
    addBlockReferenceAfterWrapper(BR_PAGE_HEADER_PANEL),
    addBlockReferenceAfter(BR_PAGE_HEADER)
];

export const footerLayoutUpdates = [
    addBlockReferenceBeforeWrapper(BR_PAGE_FOOTER),
    addBlockReferenceBefore(BR_PAGE_FOOTER_CONTAINER),
    addBlockReferenceBefore(BR_BEFORE_PAGE_FOOTER)
];

/** @namespace Bodypwa/Hoc/WithLayoutUpdates/chainedCallback */
export const chainedCallback = (originalCallback, layoutUpdates) => {
    let chainedCallback = originalCallback;

    layoutUpdates.forEach((layoutUpdate) => {
        const previousCallback = chainedCallback;
        chainedCallback = (...args) => layoutUpdate(args, previousCallback);
    });

    return chainedCallback();
};
/** @namespace Bodypwa/Hoc/WithLayoutUpdates/withLayoutUpdates */
export function withLayoutUpdates(WrappedComponent) {
  return class extends PureComponent {
    renderProductTabs(renderProductTabs) {
        return addBlockReferenceAfter(BR_PRODUCT_CONTAINER_LOWER_PRIMARY_2)([], renderProductTabs);
    }

    renderProductGallery(renderProductGallery) {
        return addBlockReferenceAfter(BR_PRODUCT_IMAGE)([], renderProductGallery);
    }

    renderRouterContent(renderRouterContent) {
        return chainedCallback(renderRouterContent, routeLayoutUpdates);
    }

    renderTopMenu(renderTopMenu) {
        return chainedCallback(renderTopMenu, topMenuLayoutUpdates);
    }

    renderFreeShippingNotification(renderFreeShippingNotification) {
        return addBlockReferenceAfter(BR_MINI_CART_PROMOTION_BLOCK)([], renderFreeShippingNotification);
    }

    renderButtons(renderButtons) {
        return addBlockReferenceBefore(BR_CHECKOUT_SUCCES)([], renderButtons);
    }

    renderContent(renderContent) {
        return chainedCallback(renderContent, footerLayoutUpdates);
    }

    renderColumns(renderColumns) {
        return addBlockReferenceBeforeWrapper(BR_CMS_FOOTER_LINKS)([], renderColumns);
    }

    renderAditional(renderAditional) {
        return addBlockReferenceBefore(BR_REVIEW_FORM_FIELDS_BEFORE)([], renderAditional);
    }

    containerProps() {
        return {
            ...this.props,
            renderProductTabs: this.renderProductTabs,
            renderProductGallery: this.renderProductGallery,
            renderRouterContent: this.renderRouterContent,
            renderFreeShippingNotification: this.renderFreeShippingNotification,
            renderButtons: this.renderButtons,
            renderTopMenu: this.renderTopMenu,
            renderContent: this.renderContent,
            renderColumns: this.renderColumns,
            renderAditional: this.renderAditional
        };
    }

    render() {
        const originalCallback = () => <WrappedComponent { ...this.containerProps() } />;

        if (WrappedComponent.name === 'FooterComponent_') {
            return addBlockReferenceBefore(BR_BEFORE_PAGE_FOOTER_CONTAINER)([], originalCallback);
        }

      return originalCallback();
    }
  };
}

export default withLayoutUpdates;
